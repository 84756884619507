import { defineComponent as a, openBlock as n, createElementBlock as l, normalizeClass as o, renderSlot as r } from "vue";
import { _ as s } from "./chunks/_plugin-vue_export-helper.CHgC5LLL.js";
import './css/Columns.css';const u = /* @__PURE__ */ a({
  __name: "Columns",
  props: {
    /**
     * The gap between columns.
     *
     * @default 'medium'
     */
    gap: {
      type: String,
      default: "medium"
    },
    /**
     * The vertical alignment of the columns.
     *
     * @default 'top'
     */
    align: {
      type: String,
      default: "top"
    },
    /**
     * Whether to wrap the columns.
     *
     * @default true
     */
    wrap: {
      type: Boolean,
      default: !0
    },
    /**
     * Specifies whether to reverse the stacking order of the column on smaller screens.
     */
    stackReverse: {
      type: Boolean,
      default: !1
    },
    /**
     * The background color of the columns.
     * This allows to render the columns as a box.
     * If this is not provided, the columns will be transparent.
     */
    background: {
      type: String
    }
  },
  setup(e) {
    return (t, c) => (n(), l("div", {
      class: o(["columns", [
        `columns-${e.gap}`,
        `columns-${e.align}`,
        `columns-${e.background ?? "transparent"}`,
        e.wrap ? "columns-wrap" : null,
        e.stackReverse ? "columns-stack-reverse" : null
      ]])
    }, [
      r(t.$slots, "default", {}, void 0, !0)
    ], 2));
  }
}), p = /* @__PURE__ */ s(u, [["__scopeId", "data-v-7ee813d1"]]);
export {
  p as default
};
